
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  name: 'UserRequestResetPassword'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  formInteracted = false

  @Ref() readonly form!: any

  formController = new FormController({
    initialModel: {
      email: ''
    },
    validationRules: {
      email: [
        ...validationRules.exists,
        ...validationRules.email
      ]
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      try {
        await this.userModule.userRequestResetPassword({
          email: model.email
        })
      } catch (err: any) {
        throw new Error(err.body?.message || err.message)
      }

      analytics.userRequestResetPassword.track(this)
    }
  }

  mounted () {
    analytics.userRequestResetPasswordScreen.track(this)

    this.formController.on('success', () => {
      this.formController.resetModel()
    })
  }

  formInteraction () {
    if (!this.formInteracted) {
      this.formInteracted = true

      analytics.userRequestResetPasswordForm.track(this)
      analytics.userRequestResetPassword.time()
    }
  }
}
